"use client";

import * as React from "react";

import { TooltipWrapper } from "../ui/TooltipWrapper";
import AutoFixNormalOutlinedIcon from "@mui/icons-material/AutoFixNormalOutlined";
import AutoFixHighOutlinedIcon from "@mui/icons-material/AutoFixHighOutlined";

interface FeatureIconsProps {
  tooltipText: string;
  numRelated: number;
  hoverDelay?: number;
  onClickText?: string;
  side?: "top" | "bottom" | "left" | "right";
  className?: string;
  showArrow?: boolean;
}

export const FeatureIcons = React.forwardRef<HTMLDivElement, FeatureIconsProps>(
  ({ tooltipText, numRelated, side = "bottom", className, showArrow = false, hoverDelay = 600, onClickText }, ref) => {
    return (
      <div className="flex cursor-none">
        <TooltipWrapper
          tooltipText={tooltipText}
          side="top"
          className="pointer-events-none max-w-[40vw] cursor-none"
          showArrow={true}
          onClickText={onClickText}
        >
          {numRelated > 0 &&
            (numRelated === 1 ? (
              <AutoFixNormalOutlinedIcon sx={{ fontSize: 14 }} className="text-border hover:text-skyBlue" />
            ) : (
              <AutoFixHighOutlinedIcon sx={{ fontSize: 14 }} className="text-border hover:text-skyBlue" />
            ))}
        </TooltipWrapper>
      </div>
    );
  }
);
FeatureIcons.displayName = "FeatureIcons";
